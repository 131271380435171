/* App.css */
/* Hide the spinners in Chrome, Safari, and Edge */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the spinners in Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
